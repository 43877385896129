import React from 'react';

/* eslint-disable max-len */

export function DownloadIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 286.054 286.054" xmlSpace="preserve" style={{ width: '20px', height: '20px' }}>
      <path d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027 s143.027-64.031,143.027-143.027C286.054,64.049,222.014,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209 s52.026-116.2,116.209-116.2s116.209,52.017,116.209,116.2S207.21,259.236,143.027,259.236z M194.704,134.088h-24.86V89.392 c0-4.934-3.996-8.939-8.939-8.939h-35.757c-4.934,0-8.939,4.005-8.939,8.939v44.696H91.341c-9.699,0-13.668,6.463-8.823,14.338 l51.686,51.096c4.845,7.884,12.783,7.884,17.637,0l51.677-51.096C208.381,140.551,204.412,134.088,194.704,134.088z" />
    </svg>
  );
}

export function WateringCanIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 221.023 221.023" xmlSpace="preserve" style={{ width: '20px', height: '20px' }}>
      <path d="M191.692,60.571h-16.95H75.444c-4.142,0-7.5,3.358-7.5,7.5v20.068l-26.94-24.284c2.287-5.944,2.612-12.58,0.703-18.91 c-0.761-2.526-2.793-4.466-5.352-5.109c-2.56-0.645-5.267,0.105-7.132,1.97L2.197,68.831c-1.866,1.865-2.614,4.574-1.97,7.132 c0.643,2.558,2.583,4.591,5.109,5.352c2.736,0.825,5.577,1.243,8.441,1.243c0,0,0,0,0,0c3.345,0,6.603-0.563,9.669-1.637 l44.497,59.39v24.531c0,9.138,7.434,16.572,16.572,16.572h81.155c9.138,0,16.571-7.434,16.571-16.572v-16.6l26.49-18.906 c7.696-5.494,12.291-14.419,12.291-23.874V89.902C221.023,73.728,207.864,60.571,191.692,60.571z M206.023,105.463 c0,4.62-2.245,8.98-6.005,11.665l-17.776,12.687V75.571h9.45c7.902,0,14.331,6.429,14.331,14.331V105.463z" />
    </svg>
  );
}

export function TextIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 470.586 470.586" xmlSpace="preserve" style={{ width: '20px', height: '20px' }}>
      <path d="M327.081,0H90.234C74.331,0,61.381,12.959,61.381,28.859v412.863c0,15.924,12.95,28.863,28.853,28.863H380.35
        c15.917,0,28.855-12.939,28.855-28.863V89.234L327.081,0z M333.891,43.184l35.996,39.121h-35.996V43.184z M384.972,441.723
        c0,2.542-2.081,4.629-4.635,4.629H90.234c-2.55,0-4.619-2.087-4.619-4.629V28.859c0-2.548,2.069-4.613,4.619-4.613h219.411v70.181
        c0,6.682,5.443,12.099,12.129,12.099h63.198V441.723z M128.364,128.89H334.15c5.013,0,9.079,4.066,9.079,9.079
        c0,5.013-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079C119.285,132.957,123.352,128.89,128.364,128.89z
         M343.229,198.98c0,5.012-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15
        C339.163,189.901,343.229,193.968,343.229,198.98z M343.229,257.993c0,5.013-4.066,9.079-9.079,9.079H128.364
        c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15C339.163,248.914,343.229,252.98,343.229,257.993z
         M343.229,318.011c0,5.013-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15
        C339.163,308.932,343.229,312.998,343.229,318.011z"
      />
    </svg>
  );
}

export function ListIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 412.766 412.767" xmlSpace="preserve" style={{ width: '20px', height: '20px' }}>
      <path d="M95.373,80.2c0,26.336-21.355,47.683-47.685,47.683c-26.327,0-47.682-21.347-47.682-47.683
        c0-26.335,21.355-47.682,47.682-47.682C74.018,32.513,95.373,53.859,95.373,80.2z M47.682,274.588
        C21.355,274.588,0,295.938,0,322.27c0,26.333,21.355,47.683,47.682,47.683c26.335,0,47.685-21.35,47.685-47.683
        C95.367,295.938,74.018,274.588,47.682,274.588z M166.769,65.312h231.895c7.784,0,14.103-6.313,14.103-14.104
        c0-7.79-6.312-14.103-14.103-14.103H166.769c-7.784,0-14.103,6.313-14.103,14.103C152.666,58.999,158.985,65.312,166.769,65.312z
         M166.769,134.257h150.426c7.791,0,14.104-6.316,14.104-14.103c0-7.791-6.312-14.103-14.104-14.103H166.769
        c-7.784,0-14.103,6.307-14.103,14.103C152.666,127.947,158.985,134.257,166.769,134.257z M398.664,283.1H166.769
        c-7.784,0-14.103,6.312-14.103,14.103s6.319,14.104,14.103,14.104h231.895c7.784,0,14.103-6.313,14.103-14.104
        S406.448,283.1,398.664,283.1z M317.195,352.043H166.769c-7.784,0-14.103,6.307-14.103,14.103c0,7.79,6.319,14.103,14.103,14.103
        h150.426c7.791,0,14.104-6.312,14.104-14.103S324.98,352.043,317.195,352.043z"
      />
    </svg>
  );
}

export function MapIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 550 530" xmlSpace="preserve" style={{ width: '20px', height: '20px' }}>
      <g>
        <path d="M436.921,75.079C389.413,27.571,326.51,1.066,259.464,0.18C258.296,0.074,257.137,0,255.999,0s-2.297,0.074-3.465,0.18
          C185.488,1.065,122.585,27.57,75.077,75.078C26.752,123.405,0.138,187.657,0.138,255.999s26.614,132.595,74.94,180.921
          c47.508,47.508,110.41,74.013,177.457,74.898c1.168,0.107,2.327,0.18,3.464,0.18c1.138,0,2.297-0.074,3.465-0.18
          c67.047-0.885,129.95-27.39,177.457-74.898c48.325-48.325,74.939-112.577,74.939-180.921
          C511.861,187.657,485.247,123.405,436.921,75.079z M96.586,96.587c27.181-27.181,60.086-46.552,95.992-57.018
          c-8.093,9.317-15.96,20.033-23.282,31.908c-9.339,15.146-17.425,31.562-24.196,48.919H75.865
          C82.165,112.063,89.071,104.102,96.586,96.587z M56.486,150.813h78.373c-8.15,28.522-12.97,58.908-14.161,89.978H31.071
          C33.176,208.987,41.865,178.465,56.486,150.813z M56.487,361.186c-14.623-27.652-23.312-58.174-25.417-89.978h89.627
          c1.191,31.071,6.011,61.457,14.161,89.978H56.487z M96.587,415.412c-7.517-7.515-14.423-15.475-20.722-23.809h69.236
          c6.771,17.357,14.856,33.773,24.196,48.919c7.322,11.875,15.189,22.591,23.282,31.908
          C156.674,461.964,123.769,442.593,96.587,415.412z M240.79,475.322c-12.671-8.29-29.685-24.946-45.605-50.764
          c-6.385-10.354-12.124-21.382-17.197-32.954h62.801V475.322z M240.79,361.186h-74.195c-8.888-28.182-14.163-58.651-15.459-89.978
          h89.654V361.186z M240.79,240.791h-89.654c1.295-31.327,6.57-61.797,15.459-89.978h74.195V240.791z M240.79,120.395h-62.801
          c5.073-11.572,10.812-22.6,17.197-32.954c15.919-25.818,32.934-42.475,45.605-50.764V120.395z M455.512,150.813
          c14.623,27.653,23.311,58.174,25.416,89.978H391.3c-1.191-31.071-6.011-61.457-14.161-89.978H455.512z M415.413,96.587
          c7.515,7.515,14.421,15.476,20.721,23.809h-69.235c-6.771-17.357-14.856-33.773-24.196-48.919
          c-7.322-11.875-15.188-22.591-23.282-31.908C355.326,50.035,388.231,69.406,415.413,96.587z M271.208,36.677
          c12.671,8.29,29.685,24.946,45.605,50.764c6.385,10.354,12.124,21.382,17.197,32.954h-62.801V36.677z M271.208,150.813h74.195
          c8.889,28.182,14.164,58.653,15.459,89.978h-89.654V150.813z M360.861,271.208c-1.295,31.327-6.57,61.797-15.459,89.978h-74.195
          v-89.978H360.861z M271.208,475.322v-83.718h62.801c-5.073,11.572-10.812,22.6-17.197,32.954
          C300.893,450.377,283.879,467.032,271.208,475.322z M415.413,415.413c-27.182,27.181-60.086,46.551-95.992,57.018
          c8.093-9.317,15.96-20.033,23.282-31.908c9.339-15.146,17.425-31.562,24.196-48.919h69.235
          C429.835,399.937,422.928,407.898,415.413,415.413z M455.512,361.186h-78.373c8.15-28.521,12.971-58.907,14.161-89.978h89.627
          C478.822,303.012,470.133,333.534,455.512,361.186z"
        />
      </g>
    </svg>
  );
}

export function DamIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 530" xmlSpace="preserve" style={{ width: '20px', height: '20px' }}>
      <g>
        <polygon points="0.678,0 0.678,443.842 139.225,443.842 62.338,0" />
        <path d="M422.888,142.41c-10.559-7.777-25.018-18.429-51.733-18.429c-26.717,0-41.177,10.651-51.734,18.429
          c-7.771,5.724-10.986,8.093-20.274,8.093c-9.284,0-12.5-2.369-20.269-8.092c-10.559-7.777-25.016-18.43-51.73-18.43
          c-26.714,0-41.174,10.651-51.73,18.429c-7.769,5.724-10.984,8.093-20.27,8.093c-9.284,0-12.5-2.369-20.268-8.092
          c-6.279-4.625-13.939-10.268-24.736-14.081l11.841,68.356c8.48,3.919,19.129,6.859,33.163,6.859
          c26.715,0,41.174-10.65,51.732-18.43c7.768-5.724,10.984-8.092,20.269-8.092c9.287,0,12.502,2.368,20.27,8.092
          c10.559,7.779,25.018,18.43,51.73,18.43c26.717,0,41.177-10.65,51.734-18.43c7.771-5.724,10.986-8.092,20.274-8.092
          c9.289,0,12.504,2.368,20.273,8.092c10.56,7.779,25.019,18.43,51.734,18.43v-53.043
          C433.875,150.503,430.658,148.134,422.888,142.41z"
        />
        <path d="M371.154,239.348c-26.717,0-41.177,10.651-51.734,18.43c-7.771,5.725-10.986,8.094-20.274,8.094
          c-9.284,0-12.5-2.369-20.269-8.092c-10.558-7.778-25.017-18.432-51.731-18.432c-26.713,0-41.173,10.651-51.729,18.43
          c-7.769,5.725-10.984,8.094-20.27,8.094c-9.284,0-12.5-2.369-20.268-8.092c-0.854-0.629-1.737-1.279-2.648-1.939l10.789,62.277
          c3.742,0.51,7.77,0.797,12.127,0.797c26.715,0,41.174-10.65,51.732-18.43c7.768-5.725,10.984-8.094,20.27-8.094
          c9.285,0,12.5,2.369,20.269,8.094c10.558,7.778,25.017,18.43,51.73,18.43c26.717,0,41.177-10.65,51.734-18.43
          c7.771-5.725,10.986-8.094,20.274-8.094c9.289,0,12.504,2.369,20.273,8.094c10.56,7.778,25.019,18.43,51.734,18.43v-53.043
          c-9.289,0-12.505-2.369-20.274-8.094C412.33,249.999,397.871,239.348,371.154,239.348z"
        />
        <path d="M371.154,357.369c-26.717,0-41.177,10.65-51.734,18.431c-7.771,5.724-10.986,8.092-20.274,8.092
          c-9.284,0-12.5-2.368-20.269-8.092c-10.558-7.777-25.017-18.431-51.731-18.431c-26.713,0-41.173,10.65-51.729,18.431
          c-7.769,5.724-10.984,8.092-20.27,8.092c-0.255,0-0.49-0.009-0.736-0.012l9.126,52.679c21.457-1.942,33.948-11.132,43.342-18.052
          c7.768-5.725,10.984-8.094,20.27-8.094c9.285,0,12.5,2.369,20.269,8.094c10.558,7.777,25.017,18.429,51.73,18.429
          c26.717,0,41.177-10.65,51.734-18.429c7.771-5.725,10.986-8.094,20.274-8.094c9.289,0,12.504,2.369,20.273,8.094
          c10.56,7.777,25.019,18.429,51.734,18.429v-53.044c-9.289,0-12.505-2.368-20.274-8.092
          C412.33,368.021,397.871,357.369,371.154,357.369z"
        />
      </g>
    </svg>
  );
}

export function WaterResIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 344.498 344.498" xmlSpace="preserve" style={{ width: '20px', height: '20px' }}>
      <g>
        <g>
          <path d="M50.552,37.249c-1.2-2-3.6-3.6-6.4-3.6c-2.8,0-5.2,1.6-6.4,3.6c-5.2,9.6-31.6,58-31.6,76c0,20.8,17.2,38,38,38
            c20.8,0,38-17.2,38-38C82.152,95.249,55.752,46.849,50.552,37.249z M44.152,136.449c-12.8,0-23.2-10.4-23.2-23.2
            c0-9.2,12.4-36,23.2-56.8c10.8,20.8,23.2,48,23.2,56.8C67.352,126.049,56.952,136.449,44.152,136.449z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M180.152,37.649c-2.4-4.8-10.4-4.8-12.8,0c-5.2,9.6-31.6,58-31.6,76c0,20.8,17.2,38,38,38s38-17.2,38-38
            C211.752,95.649,185.352,47.249,180.152,37.649z M173.752,136.449c-12.8,0-23.2-10.4-23.2-23.2c0-9.2,12.4-36,23.2-56.8
            c10.8,20.8,23.2,48,23.2,56.8C196.952,126.049,186.552,136.449,173.752,136.449z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M309.752,37.249c-2.4-4.8-10.4-4.8-12.8,0c-5.2,9.6-31.6,58-31.6,76c0,21.2,17.2,38,38,38c20.8,0,38-17.2,38-38
            C341.352,95.249,314.952,46.849,309.752,37.249z M303.352,136.449c-12.8,0-23.2-10.4-23.2-23.2c0-8.8,12.4-36,23.2-56.8
            c10.8,20.8,23.2,47.6,23.2,56.8C326.552,126.049,316.152,136.449,303.352,136.449z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M344.152,201.249c-2-4.4-6-6.4-10-5.2c-41.6,13.2-62,6.8-83.6,0c-21.2-6.4-42.8-13.2-80.8,0c-41.6,13.2-62,6.8-83.6,0
            c-21.2-6.4-42.8-13.2-81.2,0c-4,1.2-6,5.6-4.4,9.2c1.2,4,5.6,6,9.2,4.4c33.6-11.6,51.6-6,72,0.4c12.8,4,26.4,8.4,44.8,8.4
            c13.2,0,28.8-2.4,48-8.4c33.6-11.6,51.6-6,72,0.4c12.8,4,26.4,8.4,44.8,8.4c13.2,0,28.8-2.4,48-8.4
            C343.352,209.249,345.352,205.249,344.152,201.249z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M344.152,247.249c-2-4.4-6-6.4-10-5.2c-41.6,13.2-62,6.8-83.6,0c-21.2-6.4-42.8-13.2-80.8,0c-41.6,13.2-62,6.8-83.6,0
            c-21.2-6.4-42.8-13.2-81.2,0c-4,1.2-6,5.6-4.4,9.2c1.2,4,5.6,6,9.2,4.4c33.6-11.6,51.6-6,72,0.4c12.8,4,26.4,8.4,44.8,8.4
            c13.2,0,28.8-2.4,48-8.4c33.6-11.6,51.6-6,72,0.4c12.8,4,26.4,8.4,44.8,8.4c13.2,0,28.8-2.4,48-8.4
            C343.352,255.249,345.352,251.249,344.152,247.249z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M344.152,293.249c-2-4.4-6-6.4-10-5.2c-41.6,13.2-62,6.8-83.6,0c-21.2-6.4-42.8-13.2-80.8,0c-41.6,13.2-62,6.8-83.6,0
            c-21.2-6.4-42.8-13.2-81.2,0c-4,1.2-6,5.6-4.4,9.2c1.2,4,5.6,6,9.2,4.4c33.6-11.6,51.6-6,72,0.4c12.8,4,26.4,8.4,44.8,8.4
            c13.2,0,28.8-2.4,48-8.4c33.6-11.6,51.6-6,72,0.4c12.8,4,26.4,8.4,44.8,8.4c13.2,0,28.8-2.4,48-8.4
            C343.352,301.249,345.352,297.249,344.152,293.249z"
          />
        </g>
      </g>
    </svg>
  );
}
