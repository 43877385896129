import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import styles from './styles.module.scss';

const CountryList = ({ data, listTitle }) => {
  const { allMarkdownRemark: { edges } } = data;
  const countryProfiles = [...edges];
  const initials = countryProfiles
    .map(({ node: { frontmatter: { title } } }) => title)
    .reduce((acc, val) => {
      acc.push(val[0]);
      return acc;
    }, []);
  const uniqueInitials = Array.from(new Set(initials)).map(letter => ({ node: { frontmatter: { title: letter } } }));
  countryProfiles.push(...uniqueInitials);
  countryProfiles.sort((a, b) => {
    if (a.node.frontmatter.title > b.node.frontmatter.title) {
      return 1;
    }
    if (a.node.frontmatter.title < b.node.frontmatter.title) {
      return -1;
    }

    return 0;
  });

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{listTitle}</h2>
      <div className={styles.countryList}>
        {countryProfiles.map(({ node: { frontmatter: { title, path } } }) => {
          if (!path) {
            return <div key={title} className={styles.initial}>{title}</div>;
          }

          return (
            <Link key={path} to={path} className={styles.countryLink}>
              {title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

CountryList.propTypes = {
  data: PropTypes.object.isRequired,
  listTitle: PropTypes.string,
};

CountryList.defaultProps = {
  listTitle: 'Country List',
};

export default CountryList;
