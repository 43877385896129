import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import styles from './styles.module.scss';

const BackLink = ({ to, children }) => (
  <div className={styles.backLink}>
    <Link to={to}>
      {children}
    </Link>
  </div>
);

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default BackLink;
