import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { navigateTo } from 'gatsby-link';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import TopMenu from '../components/TopMenu';
import Footer from '../components/Footer';
import SubMenu from '../components/SubMenu';
import RegionalOverviewsMap from '../components/RegionalOverviewsMap';
import CountryProfile from '../components/CountryProfile';
import CountryProfileList from '../components/CountryProfileList';
import IrrigationCountryList from '../components/IrrigationCountryList';
import ReportsAndBrochures from '../components/ReportsAndBrochures';
import BackLink from '../components/BackLink';

import { menuNodesSorter, getCodeByCountryName } from '../utils';

import styles from './styles.module.scss';

export default class PageTemplate extends Component {
  constructor() {
    super();

    this.state = {
      subPages: [],
    };
  }

  componentDidMount = () => {
    const { data: { allMarkdownRemark: { edges } } } = this.props;

    const subPages = edges
      .sort(menuNodesSorter)
      .map(({ node }) => {
        const { path, title } = node.frontmatter;

        // Exclude the section "index" file, to be rendered
        // in the body of the page, and any potential child pages.
        if (path.endsWith('/index') || path.split('/').length >= 4) {
          return null;
        }

        return {
          link: path.endsWith('/') ? path : `${path}/`,
          label: title,
          children: this.findPageChildren(path),
        };
      })
      .filter(Boolean);

    this.setState({
      subPages,
    });
  }

  onRegionMapClick = region => {
    navigateTo(`/countries-and-basins/regional-overviews/${region}/`);
  }

  findPageChildren(parentPath) {
    const { data: { allMarkdownRemark: { edges } } } = this.props;

    return edges.filter(({ node }) => {
      const nodeProps = node.frontmatter;

      // If the node path is shorter or equal to the parent
      // path it means it's not a children. Also, if it's a
      // 4th-level page, don't include it in the direct children list.
      const isChildPage = nodeProps.path.length <= parentPath.length;
      const isFourthLevel = nodeProps.path.split('/').length > 4;

      if (isChildPage || isFourthLevel) {
        return false;
      }

      if (nodeProps.path.startsWith(parentPath)) {
        return true;
      }

      return false;
    }).sort(menuNodesSorter);
  }

  renderBody = () => {
    const { data: { markdownRemark: { html, frontmatter } } } = this.props;
    const isPublications = frontmatter.path.startsWith('/publications/');
    const isCountryProfile = frontmatter.path.startsWith('/countries-and-basins/country-profiles/');
    const isIrrigationMapPage = frontmatter.path.startsWith('/geospatial-information/global-maps-irrigated-areas/irrigation-by-country/country/');
    const isIrrigationMaps = frontmatter.path === '/geospatial-information/global-maps-irrigated-areas/irrigation-by-country';
    const isCountryProfiles = frontmatter.path === '/countries-and-basins/country-profiles';
    const isRegionalOverviews = frontmatter.path === '/countries-and-basins/regional-overviews';
    const countryCode = isCountryProfile && getCodeByCountryName(frontmatter.title.toLowerCase());
    const publicationCategory = frontmatter.path.split('/').filter(Boolean)[1];

    return (
      <div style={{ position: 'relative' }}>
        {isIrrigationMapPage && (
          <BackLink to="/geospatial-information/global-maps-irrigated-areas/irrigation-by-country">« Go back to country list</BackLink>
        )}

        <div
          className={classnames(styles.content, {
            'country-profile': isCountryProfile,
            'irrigation-map': isIrrigationMapPage,
            'river-basin': frontmatter.path.startsWith('/countries-and-basins/transboundary-river-basins'),
          })}
          dangerouslySetInnerHTML={{ __html: html }}
        />

        {isCountryProfile && <CountryProfile countryCode={countryCode} {...frontmatter} />}
        {isCountryProfiles && <CountryProfileList />}
        {isIrrigationMaps && <IrrigationCountryList listTitle="Sub-national statistics" />}

        {isPublications && (
          <div className={styles.publicationsWrapper}>
            <h2 style={{ textTransform: 'capitalize' }}>{publicationCategory}</h2>
            <ReportsAndBrochures category={publicationCategory} />
          </div>
        )}

        {isRegionalOverviews && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <RegionalOverviewsMap onRegionClick={this.onRegionMapClick} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { data: { site, markdownRemark }, location } = this.props;
    const { subPages } = this.state;
    const pageClass = markdownRemark.frontmatter.title
      ? markdownRemark.frontmatter.title.toLowerCase().replace(/\s/gi, '-') // poor's man kebab-case
      : null;

    return (
      <div className={pageClass}>
        <Header siteTitle={site.siteMetadata.title} location={{}} />

        <div className="app-wrapper">
          <h2 className="site-title">AQUASTAT - FAO&apos;s Information System on Water and Agriculture</h2>
          <TopMenu location={location.pathname} />

          <div className={styles.wrapper}>
            <div className={styles.subMenuWrapper}>
              <SubMenu links={subPages} currentPath={location.pathname} />
            </div>

            {this.renderBody()}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

/* eslint-disable no-undef */
export const pageQuery = graphql`
  query PageByPath($path: String!, $categoryRegexp: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        pdfLink
        year
        lang
      }
    }
    allMarkdownRemark(filter: { frontmatter: { path: { regex: $categoryRegexp }}}) {
      edges {
        node {
          id
          html
          frontmatter {
            path
            title
            menuOrder
          }
        }
      }
    }
  }
`;
