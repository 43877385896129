import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { yearOrderSorter } from '../../utils';

import styles from './styles.module.scss';

const ReportsAndBrochures = ({ data: { allMarkdownRemark: { edges } }, category }) => {
  const entries = edges
    .filter(({ node: { frontmatter: { path, category: cat } } }) => path.startsWith('/reports-brochures') && category === cat)
    .sort(yearOrderSorter)
    .map(({ node: { html, frontmatter: { path, title, image } } }) => ({
      title,
      image,
      path,
      body: html,
    }));

  return (
    <div>
      {entries.map(({ path, title, image, body }) => (
        <div key={path} className={styles.reportsEntry}>
          <div className={styles.entryImage}>
            <img src={image} alt={title} />
          </div>
          <div className={styles.entryBody}>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
      ))}
    </div>
  );
};

ReportsAndBrochures.propTypes = {
  data: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {path: {regex: "/^\/reports-brochures/i" }}}) {
          edges {
            node {
              id
              html
              frontmatter {
                path
                title
                image
                year
                category
              }
            }
          }
        }
      }
    `}
    render={data => <ReportsAndBrochures data={data} {...props} />}
  />
);
