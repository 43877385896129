import React from 'react';
import PropTypes from 'prop-types';

import { countriesWithoutDams } from '../../utils';
import { TextIcon, ListIcon, WaterResIcon, WateringCanIcon, DamIcon, MapIcon } from './icons';

import styles from './styles.module.scss';

const CountryProfile = ({ countryCode, lang, year, pdfLink, title }) => (
  <div className={styles.container}>
    <h2>{`Country Profile - ${title}`}</h2>
    {year && <h3>Year: {year}</h3>}

    <div className={styles.info}>
      <div className={styles.image}>
        <img
          className={styles.mapImage}
          src={`https://storage.googleapis.com/fao-aquastat.appspot.com/countries_regions/images/${countryCode}-map_small.png`}
          alt={`${title} Map`}
        />
      </div>

      <div className={styles.factsheetContainer}>
        <h3>Factsheets</h3>

        <div className={styles.factsheet}>
          <ul className={styles.column}>
            <li>
              <a
                className={styles.factLink}
                href={pdfLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TextIcon />
                <span>Report {lang && `(${lang})`}</span>
              </a>
            </li>
            <li>
              <a
                className={styles.factLink}
                href={`http://www.fao.org/nr/water/aquastat/data/cf/readPdf.html?f=${countryCode}-CF_eng.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListIcon />
                <span>Summary statistics</span>
              </a>
            </li>
            <li>
              <a
                className={styles.factLink}
                href={`http://www.fao.org/nr/water/aquastat/data/wrs/readPdf.html?f=${countryCode}-WRS_eng.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WaterResIcon />
                <span>Water resources</span>
              </a>
            </li>
          </ul>

          <ul className={styles.column}>
            <li>
              <a
                className={styles.factLink}
                href={`http://www.fao.org/nr/water/aquastat/data/irrs/readPdf.html?f=${countryCode}-IRR_eng.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WateringCanIcon />
                <span>Irrigation areas</span>
              </a>
            </li>
            {!countriesWithoutDams.includes(countryCode) && (
              <li>
                <a
                  className={styles.factLink}
                  href={`https://storage.googleapis.com/fao-aquastat.appspot.com/Excel/dams/${countryCode}-dams_eng.xlsx`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DamIcon />
                  <span>Dams</span>
                </a>
              </li>
            )}
            <li>
              <a
                className={styles.factLink}
                href={`https://storage.googleapis.com/fao-aquastat.appspot.com/countries_regions/pdf/${countryCode}-map_detailed.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapIcon />
                <span>Country Map</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <p>
      The country profile is a summary of key information that gives an overview of the water resources and water use at the national level.
      It can support water-related policy and decision makers in their planning and monitoring activities as well as inform researchers,
      media and the general public. Information in the report is organized by sections:
    </p>

    <ul>
      <li>Geography, Climate and Population</li>
      <li>Economy, Agriculture and Food Security</li>
      <li>Water Resources</li>
      <li>Water Use</li>
      <li>Irrigation and Drainage</li>
      <li>Water Management</li>
      <li>Policies and Legislation Related to Water Use In Agriculture</li>
      <li>Environment and Health</li>
      <li>Prospects for Agricultural Water Management</li>
    </ul>

    <p>All sources used to compile the country profile are also reported in the last section of the document.</p>

    <p>The factsheets below outline the main data by theme and map the main features of water and agriculture in the country.</p>

    <p>
      For the most recent data, please check the
      {' '}
      <a href="http://www.fao.org/nr/water/aquastat/data/query/index.html?lang=en" target="_blank" rel="noopener noreferrer">
        <strong>
          Country Statistics
        </strong>
      </a>
      .
    </p>
  </div>
);

CountryProfile.propTypes = {
  countryCode: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CountryProfile;
