import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import CountryList from '../CountryList';

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {path: {regex: "/^\/geospatial-information\/global-maps-irrigated-areas\/irrigation-by-country\/country\//i" }}}) {
          edges {
            node {
              id
              html
              frontmatter {
                path
                title
              }
            }
          }
        }
      }
    `}
    render={data => <CountryList data={data} {...props} />}
  />
);
